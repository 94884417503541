.toast-viewport {
  --viewport-margin: 3rem;
  @apply fixed bottom-0 right-0 z-50 m-0 flex max-w-full list-none flex-col-reverse items-end gap-2 outline-none;
  margin: var(--viewport-margin);
}

.toast-root {
  @apply flex justify-between gap-4 rounded-md p-4;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

  &.toast-root-neutral {
    @apply bg-surface-tokens-neutral;
    .toast-title,
    .toast-description,
    .toast-action,
    .toast-close-icon,
    .toast-icon {
      @apply text-text-primary;
    }
  }
  &.toast-root-success {
    @apply bg-surface-tokens-success;
  }
  &.toast-root-warning {
    @apply bg-surface-tokens-warning;
  }
  &.toast-root-error {
    @apply bg-surface-tokens-error;
  }
  &.toast-root-info {
    @apply bg-surface-tokens-informative;
  }
}

.toast-text {
  @apply flex flex-col gap-2;
}

.toast-title {
  @apply text-label font-medium text-text-contrast;
}

.toast-description {
  @apply text-caption text-text-contrast;
}

.toast-action {
  @apply text-left text-caption font-medium text-text-contrast;
}

.toast-close-button {
  @apply flex;
}

.toast-close-icon {
  @apply pt-0.5 text-text-contrast;
}

.toast-icon {
  @apply pt-0.5 text-text-contrast;
}

.toast-root[data-state="open"] {
  animation: slideIn 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toast-root[data-state="closed"] {
  animation: hide 200ms ease-in;
}

.toast-root[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}

.toast-root[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}

.toast-root[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-margin)));
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(calc(100% + var(--viewport-margin)));
  }
}
